import React, {useEffect, useState} from 'react';
import {type stat} from "../services/statHelper";
import TableHelper from "../services/tableHelper";
import RecordStar from "./RecordStar";

type StatsTableProps = {
    averages: stat[];
    setTable?: boolean;
};

export default function StatsTable(props: StatsTableProps): React.JSX.Element {
    const [asc, setAsc] = useState(true)

    useEffect(() => {
        setPlayers(getPlayers("name", asc))
    }, [props.setTable])

    const getPlayers = (sortField: string, asc: boolean): React.JSX.Element[] => {
        const players: React.JSX.Element[] = [];
        props.averages.sort(function (a, b) {
            //this first if else puts Team in the last row
            if (a.name === 'Team') {
                return 1;
            } else if (b.name === 'Team') {
                return -1;
            }

            return TableHelper.sortRows(a, b, sortField, asc);
        }).forEach((player) => {
            const textClass =
                player.name === 'Team'
                    ? 'bg-white font-bold text-center border-2 border-black'
                    : 'text-white border-2 border-red-600 text-center';
            players.push(
                <tr key={player.name} className="border-red-900 border-t-2">
                    <td
                        className={textClass + ' absolute w-36 -ml-36 -mt-0.5 bg-black'}
                    >
                        {player.name}
                    </td>
                    <td className={textClass}>{player.no}</td>
                    <td className={textClass}>{player.gamesPlayed}<RecordStar playerNo={player.no}
                                                                              stats={props.averages} max={true}
                                                                              statField={"gamesPlayed"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.fgMade2pt.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                       stats={props.averages} max={true}
                                                                                       statField={"fgMade2pt"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.fgAtt2pt.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                      stats={props.averages} max={true}
                                                                                      statField={"fgAtt2pt"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.fg2pt.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                   stats={props.averages} max={true}
                                                                                   statField={"fg2pt"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.fgMade3pt.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                       stats={props.averages} max={true}
                                                                                       statField={"fgMade3pt"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.fgAtt3pt.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                      stats={props.averages} max={true}
                                                                                      statField={"fgAtt3pt"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.fg3pt.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                   stats={props.averages} max={true}
                                                                                   statField={"fg3pt"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.fgMade.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                    stats={props.averages} max={true}
                                                                                    statField={"fgMade"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.fgAtt.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                   stats={props.averages} max={true}
                                                                                   statField={"fgAtt"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.fg.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                stats={props.averages} max={true}
                                                                                statField={"fg"}></RecordStar></td>
                    <td className={textClass}>{player.ftMade.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                    stats={props.averages} max={true}
                                                                                    statField={"ftMade"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.ftAtt.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                   stats={props.averages} max={true}
                                                                                   statField={"ftAtt"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.ft.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                stats={props.averages} max={true}
                                                                                statField={"ft"}></RecordStar></td>
                    <td className={textClass}>{player.offRebound.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                        stats={props.averages}
                                                                                        max={true}
                                                                                        statField={"offRebound"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.defRebound.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                        stats={props.averages}
                                                                                        max={true}
                                                                                        statField={"defRebound"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.reboundTot.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                        stats={props.averages}
                                                                                        max={true}
                                                                                        statField={"reboundTot"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.assist.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                    stats={props.averages} max={true}
                                                                                    statField={"assist"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.turnover.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                      stats={props.averages} max={false}
                                                                                      statField={"turnover"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.block.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                   stats={props.averages} max={true}
                                                                                   statField={"block"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.steal.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                   stats={props.averages} max={true}
                                                                                   statField={"steal"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.personalFoul.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                          stats={props.averages}
                                                                                          max={false}
                                                                                          statField={"personalFoul"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.points.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                    stats={props.averages} max={true}
                                                                                    statField={"points"}></RecordStar>
                    </td>
                    <td className={textClass}>{player.eff.toFixed(2)}<RecordStar playerNo={player.no}
                                                                                 stats={props.averages} max={true}
                                                                                 statField={"eff"}></RecordStar></td>
                </tr>
            );
        });
        return players
    }

    const [players, setPlayers] = useState(getPlayers("name", asc))

    const thClassName = 'cursor-pointer text-white border-2 border-red-600';

    return (
        <table className="ml-36">
            <thead>
            <tr>
                {/*use this character if you want to have non line breaking space: &nbsp;   */}
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('name', asc));
                    }}
                    className={thClassName + ' absolute w-36 -ml-36 -mt-0.5 bg-black'}
                >
                    Player
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('no', asc));
                    }}
                    className={thClassName}
                >
                    No
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('gamesPlayed', asc));
                    }}
                    className={thClassName}
                >
                    GP
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgMade2pt', asc));
                    }}
                    className={thClassName}
                >
                    2FGM
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgAtt2pt', asc));
                    }}
                    className={thClassName}
                >
                    2FGA
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fg2pt', asc));
                    }}
                    className={thClassName}
                >
                    2FG%
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgMade3pt', asc));
                    }}
                    className={thClassName}
                >
                    3FGM
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgAtt3pt', asc));
                    }}
                    className={thClassName}
                >
                    3FGA
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fg3pt', asc));
                    }}
                    className={thClassName}
                >
                    3FG%
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgMade', asc));
                    }}
                    className={thClassName}
                >
                    FGM
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgAtt', asc));
                    }}
                    className={thClassName}
                >
                    FGA
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fg', asc));
                    }}
                    className={thClassName}
                >
                    FG%
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('ftMade', asc));
                    }}
                    className={thClassName}
                >
                    FTM
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('ftAtt', asc));
                    }}
                    className={thClassName}
                >
                    FTA
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('ft', asc));
                    }}
                    className={thClassName}
                >
                    FT%
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('offRebound', asc));
                    }}
                    className={thClassName}
                >
                    OREB
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('defRebound', asc));
                    }}
                    className={thClassName}
                >
                    DREB
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('reboundTot', asc));
                    }}
                    className={thClassName}
                >
                    REB
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('assist', asc));
                    }}
                    className={thClassName}
                >
                    AST
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('turnover', asc));
                    }}
                    className={thClassName}
                >
                    TO
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('block', asc));
                    }}
                    className={thClassName}
                >
                    BLK
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('steal', asc));
                    }}
                    className={thClassName}
                >
                    STL
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('personalFoul', asc));
                    }}
                    className={thClassName}
                >
                    PF
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('points', asc));
                    }}
                    className={thClassName}
                >
                    PTS
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('eff', asc));
                    }}
                    className={thClassName}
                >
                    EFF
                </th>
            </tr>
            </thead>
            <tbody>{players}</tbody>
        </table>
    );
}
