import React from 'react';
import {getStatField, type stat} from '../services/statHelper';

type RecordStarProps = {
    stats: stat[];
    playerNo?: number;
    max: boolean;
    statField: string;
};

function maxStat(stats: stat[], statField: string): stat {
    return stats.reduce(function (prev, current) {
        const prevField = getStatField(prev, statField);
        const currentField = getStatField(current, statField);
        return prev && prevField > currentField ? prev : current;
    }); //returns object
}

function minStat(stats: stat[], statField: string): stat {
    return stats.reduce(function (prev, current) {
        const prevField = getStatField(prev, statField);
        const currentField = getStatField(current, statField);
        return prev && prevField < currentField ? prev : current;
    }); //returns object
}

export default function RecordStar(props: RecordStarProps): React.JSX.Element {
    if (props.playerNo === undefined) {
        return <></>;
    }

    const stats = props.stats.filter((stat) => stat.no !== undefined);

    const player = props.max
        ? maxStat(stats, props.statField)
        : minStat(stats, props.statField);

    if (player.gamesPlayed === 0) {
        return <></>;
    }

    const bestPlayers = stats
        .filter((stat) => {
            const field = getStatField(stat, props.statField);
            const bestField = getStatField(player, props.statField); //called best because it could be max or min
            return field === bestField;
        })
        .map((stat) => stat.no);

    if (bestPlayers.includes(props.playerNo)) {
        return (
            // <div className="float-left">&#11088;</div>
            <>&#11088;</>
        );
    } else {
        return <></>;
    }
}
